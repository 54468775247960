import { Button } from '@itau-loans-www/shopping/src/components'
import Layout from '@itau-loans-www/shopping/src/layouts/LandingPage'
import { globalHistory } from '@reach/router'

import './page-404.scss'

const Page404 = () => {
  const path = globalHistory.location.pathname
  const productPathName = path.split('/')[1]

  const link = `/${productPathName}` === path ? '/' : `/${productPathName}`

  return (
    <Layout hasCookieBar={false}>
      <div className="page-404__container">
        <div className="page-404__row">
          <div className="page-404__content">
            <h2 className="page-404__title">Página não encontrada</h2>

            <p className="page-404__text">
              Parece que você está tentando acessar uma página que foi deletada
              ou não existe mais. Você pode voltar para a página anterior
              clicando no botão abaixo.
            </p>

            <a href={link} className="page-404__link">
              <Button fluid>ir para a página inicial</Button>
            </a>
          </div>

          <div className="page-404__image">
            <div className="page-404__image-wrapper">
              <p className="page-404__image-text">404</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page404
